import React, { useState, useEffect } from 'react'
import { HomeIcon, PencilIcon, BuildingLibraryIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import { 
  Typography,
  Card,
  CardBody,
  Input,
  Slider,
  Button,
  Spinner,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel, 
  slider
} from "@material-tailwind/react";

import { v4 as uuidv4 } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import '../custom-toast.css';
import NatDogViewer from './NatDogViewer';

export default function Indexer() {
  
  return (
    <div className="flex flex-col gap-5 md:p-10 p-5 bg-[#F0F0F0] min-h-[100vh] items-center w-full">
      <NatDogViewer />
    </div>
  )
}
